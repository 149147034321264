.loading-container .AsyncLoader {
  margin-top: 2.2rem;
}

.loading-container .loading-description {
  margin-top: 1.8rem;
}

.loading-container .stop-generator {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  color: #647696;
  letter-spacing: 0.02em;
}

.loading-container .stop-text {
  margin-left: 1rem;
}

@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap);
* {
  font-family: "Inter", sans-serif;
}
html {
  font-size: 62.5%;
  min-width: -webkit-max-content;
  min-width: max-content;
}
body {
  font-size: 16px;
  overflow: hidden;
}
p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  line-height: 185%;
}

a {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c5ce7;
  text-decoration: none;
}

/* global loading styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  font-size: 1.4rem;
  line-height: 185%;
  color: #475161;
  white-space: nowrap;
  height: 15rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.short-container {
  height: 18rem;
}

.loading-container .contentstack-logo-gif {
  height: 5rem;
  width: 4.2rem;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  font-size: 1.4rem;
  line-height: 185%;
  color: #475161;
  white-space: nowrap;
  padding: 3rem 1.6rem;
  max-width: 30rem;
  grid-gap: 0rem;
  gap: 0rem;
}

.error-container .main-section {
  text-align: center;
}

.error-container .main-section h4 {
  margin-top: 0.4rem;
}

.error-container img {
  height: 3.2rem;
  width: 3.2rem;
}

.error-container .error-description {
  white-space: normal;
  text-align: center;
  font-weight: 400;
}

/* common properties for app component content*/

.app-component-content {
  padding: 20px;
  text-align: center;
}

.app-component-content > p {
  padding: 20px;
}

.loading-description {
  margin-top: 1rem;
  white-space: normal;
  width: 18rem;
  text-align: center;
}

.AsyncLoader--dot {
  height: 1.3rem;
  width: 1.3rem;
  margin-right: 1rem;
}

[id^="tippy"] {
  max-width: 30rem;
}

/* asset sidebar styles */

.asset-sidebar {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 550px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #a9b6cb;
  text-align: center;
  margin: 1rem;
  border-radius: 8px;
}

.asset-sidebar-icon {
  display: flex;
  justify-content: center;
}

.asset-sidebar-icon > img {
  width: 52px;
  height: 52px;
}

.app-component-content > h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.00019em;
  color: #222222;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

/* app configuration extension styles  */

.app-config {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 80rem;
  justify-content: center;
  align-items: center;
  margin: 15px;
  border-radius: 8px;
  overflow: auto;
}

.app-config-icon {
  display: flex;
  justify-content: center;
}

.app-config-icon > img {
  width: 52px;
  height: 52px;
}

/* custom field styles */

.custom-field {
  box-sizing: border-box;
  display: flex;
  height: 260px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed #a9b6cb;
  border-radius: 8px;
}

.custom-field-container {
  height: 218px;
}

.custom-field-content {
  padding: 20px 0;
}

.custom-field-icon {
  display: flex;
  justify-content: center;
}

.custom-field-icon > img {
  width: 52px;
  height: 52px;
}

/* entry field styles */

.entry-field-container {
  padding: 2rem 0;
  width: 30rem;
}
.entry-field-container .Search .Search__input.regular-corners {
  font-size: 1.2rem;
  padding-left: 2.7rem;
  line-height: 1.4rem;
  height: 3.4rem;
}

.entry-field-container .Search--primary .Search__search-icon {
  height: 1.2rem;
  width: 1.2rem;
  left: 1rem;
}

.entry-field-container .Search__cancel-icon {
  display: flex;
  align-items: center;
}

.entry-field-container .Search__cancel-icon svg {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.entry-field-container .icons {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.entry-field-container .icons .chevron * {
  fill: #647696;
}

.entry-field-container .icons circle {
  fill: #647696;
}

.entry-field-container .icons * {
  stroke: #647696;
  pointer-events: none;
}

.entry-field-container .nested-child li {
  padding-left: 1rem;
}

.entry-field-container li.hide {
  display: none !important;
}

.tippy-box-dark > .tippy-content {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

/* dashboard styles */

.dashboard {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 320px;
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  padding: 8px 0px 8px 0px;
}

.dashboard-icon {
  display: flex;
  justify-content: center;
}

/* entry sidebar styles */

.entry-sidebar {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 533px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed #a9b6cb;
  border-radius: 8px;
}

.entry-sidebar-content {
  padding: 20px 0;
}

.entry-sidebar-icon {
  display: flex;
  justify-content: center;
}

.entry-sidebar-icon > img {
  width: 52px;
  height: 52px;
}

/* JSONRte Extension */

.jsonrte-extension {
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.jsonrte-extension > label {
  font-weight: 700;
}

.jsonrte-extension > p {
  padding: 0.25rem;
  color: #000000;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-width: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* app failed component */

.app-failed-container {
  padding: 5rem;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
}

.app-failed-container > .app-component-container {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fdf2c7;
  border-radius: 0.5rem;
}

.app-icon {
  margin-right: 0.5rem;
  color: #b36b34;
  width: 1.25rem;
  height: 1.25rem;
}

.app-content {
  display: flex;
  align-items: center;
}

.app-content > h3 {
  color: #b36b34;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.app-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #b36b34;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.secondary-app-container {
  display: flex;
}

.secondary-app-container > button {
  display: inline-flex;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-right: 0.5rem;
  background-color: #b36b34;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  text-align: center;
  align-items: center;
  border-radius: 0.5rem;
}

.app-learn-more {
  margin-right: 0.5rem;
  margin-left: -0.125rem;
  width: 1rem;
  height: 1rem;
}

.ui-location {
  list-style: circle;
  padding: 0 40px;
  margin-top: -20px;
}

.ui-location li {
  text-align: left;
}

.app-config-missing-container,
.app-config-missing-container .config-missing-icon {
  display: flex;
  justify-content: center;
}

.app-config-missing-container .config-missing-main {
  padding: 2rem;
  text-align: center;
  min-height: 29.4rem;
}

.app-config-missing-container .config-missing-title h4 {
  font-family: Inter;
  color: #475161;
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 21px;
}

.config-missing-description,
.config-missing-redirect {
  font-size: 1.4rem;
  max-width: 30rem;
  line-height: 185%;
  white-space: normal;
  text-align: center;
  font-weight: 300;
}

.mt-1 {
  margin-top: 1rem;
}

.ReactModal__Body--open {
  margin-top: 16.5rem;
}

.loading-container .AsyncLoader {
  margin-top: 2.2rem;
}

.loading-container .loading-description {
  margin-top: 1.8rem;
}

.loading-container .stop-generator {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  color: #647696;
  letter-spacing: 0.02em;
}

.loading-container .stop-text {
  margin-left: 1rem;
}

